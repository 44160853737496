function scroll_sidebar(sidebarTop){
  let w = $(window).width();
  
  if( w >= 1199 && $('.wrapper_fixed').length){
    if($('.wrapper_fixed'.length)){
      let el = $('.wrapper_fixed');
      let sidebarHeight = $('.wrapper_fixed').height();
      let limit = $('.footercontainer').offset().top - sidebarHeight - 15 ;
      let windowsTop = $(window).scrollTop();
      
      if(sidebarTop - 20 < windowsTop){
        el.css({
          position: 'relative',
          top: windowsTop - sidebarTop + 20
        });
      }else
      {
        el.css({
          position:'relative',
          top: 0
        });
      }
  
      if(limit < windowsTop){
        let diff = limit - windowsTop;
        el.css({
          top: diff
        });
      }
    } 
  } else if (w < 1199) {
    let el = $('.wrapper_fixed');
    el.css({
      position:'relative',
      top: 0
    });
  }
}



$(document).ready(function() {
  
  if(document.getElementById('modal')){
    // Get the modal
    var modal = document.getElementById("modal");

    // Get the button that opens the modal
    var btn = document.getElementById("btn_opt");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on the button, open the modal 
    btn.onclick = function() {
      modal.style.display = "block";
    }

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal.style.display = "none";
    }

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
  var width_window =  $(window).width();
  $(window).resize(function(e){
    width_window =  $(window).width();
  })
    
    $("#nav-icon3").click(function () {
      if(width_window <= 992){
        $(this).toggleClass("open");
        $("nav.mobile_menu").slideToggle("slow")
        $(".drop").children(".dropdown").removeClass("activee").slideUp()
      }
    })

    $(".drop").click(function () {
      if(width_window <= 992){
        if($(this).children(".dropdown").hasClass("activee")){
          $(this).children(".dropdown").removeClass("activee")
          $(this).children(".dropdown").slideUp(200)
        }else{
          $(".drop").children(".dropdown").removeClass("activee").slideUp(200)
          $(this).children(".dropdown").addClass("activee")
          $(this).children(".dropdown").slideDown(200)
        }
      }
    })
      
 
  
  $("ul .languagepicker_mobile").click(function(){
    if($(this).hasClass('open_language')){
      $(this).removeClass('open_language')
    }else{
      $(this).addClass('open_language')
    }
  })

	$('.carusel_first').slick({
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: $('.btn_left'),
        nextArrow: $('.btn_right'),
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 600,
            settings: { 
              slidesToShow: 1,
              slidesToShow: 1 
            }
           },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
          }          
          
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      })

  $('.carusel_last').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('.btn_left_last'),
    nextArrow: $('.btn_right_last'),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
      }
      }  
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  })
  if($('.wrapper_fixed').length){
    var sidebarTop = $('.wrapper_fixed').offset().top;
  }

  $(window).scroll(function(e){
    scroll_sidebar(sidebarTop);
  });
    
  $('.howitwork_wrapper_content_item_content_text p.howitwork_read_more').click(function(e){      
    let parent = e.target.closest('.howitwork_wrapper_content_item_content_text');
    $(parent.children[0]).slideToggle();
    $(parent.children[1]).slideToggle();
    $(parent.children[2]).slideToggle();
    $(parent.children[3]).slideToggle();
  })
  $('.howitwork_wrapper_content_item_content_text p.howitwork_read_less').click(function(e){      
    let parent = e.target.closest('.howitwork_wrapper_content_item_content_text');
    $(parent.children[0]).slideToggle();
    $(parent.children[1]).slideToggle();
    $(parent.children[2]).slideToggle();
    $(parent.children[3]).slideToggle();
  })

  $('.accordian h3, h3 span').first().addClass('closed');
  $('.accordian h3 span').first().addClass('closed');
  $('.accordian ul ul').first().addClass('open');
  $('.accordian ul ul').first().show();
  $('.accordian li').on('click', function() {

    if ($(this).find('ul').hasClass('open')) {
      $('ul.open').slideToggle().removeClass('open');
      $('.accordian h3 span').removeClass('closed');
      $('.accordian h3').removeClass('closed');
    } else {
      $('ul.open').slideToggle().removeClass('open');
      $(this).find('ul').slideToggle().addClass('open');
      $('.accordian h3, .accordian h3 span').removeClass('closed');
      $(this).find('h3, h3 span').addClass('closed')
    }
  });
  // Active class adds open class
  $('.accordian li.active ul').slideDown().addClass('open');
 
  $('.contact_wrapper_content_form_btn input').click(function(){
    validate(event);
  });

  $('.blog_wrapper_sidebar_two_content_form form button').click(function(){
    validate(event);
  });

  $('.testimonials_container_form form button').click(function(){
    validate(event);
  });
  $('.modal_content_form form button').click(function(){
    validate(event);
  });
  

  var reg_name = /^([A-zÀ-ÿA-åА-я.\-']{3,32})( [A-zÀ-ÿA-åА-я.\-']{3,32})?( [A-zÀ-ÿA-åА-я.\-']{3,32})?$/;
  var req_email = /^([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~][.]{0,1}){1,63}[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]@[a-zA-Z0-9][a-zA-Z0-9.\-]{1,63}[a-zA-Z0-9]\.[a-zA-Z]{2,4}$/;
  
  function validate(){
    let userName = $("#userName");
    let userEmail = $("#userEmail");
    let userMessage = $("#userMessage");

    if(!reg_name.test(userName.val())){
      userName.addClass('error')
    }else{
      userName.removeClass('error')
      userName.addClass('correctly')
    }
    if(!req_email.test(userEmail.val())){
      userEmail.addClass('error')
    }else{
      userEmail.removeClass('error')
      userEmail.addClass('correctly')
    }
    if(!userMessage.val()){
      userMessage.addClass('error')
    }else{
      userMessage.removeClass('error')
      userMessage.addClass('correctly')
    }
  }

  function validateEmail(email){
    var req_email = /^([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~][.]{0,1}){1,63}[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]@[a-zA-Z0-9][a-zA-Z0-9.\-]{1,63}[a-zA-Z0-9]\.[a-zA-Z]{2,4}$/;
    return req_email.test(email);
  }

  $('#contactForm').submit(function(e){
    e.preventDefault();
    
    var email = $('#userEmail').val();

    if(validateEmail(email)){
      $('.contact_wrapper_content_form_btn input').attr('disabled', true);
      $.post('/send/', $('#contactForm').serialize(), function (data) {
       if(data.result === true){
        $('#contactForm').trigger("reset");
        let userName = $("#userName");
        let userEmail = $("#userEmail");
        let userMessage = $("#userMessage");
        userName.removeClass('correctly');
        userEmail.removeClass('correctly');
        userMessage.removeClass('correctly');
       }else{
        console.log('error');
       }
       $('.contact_wrapper_content_form_btn input').attr('disabled', false);
      })
    }
  })
  $('#testimonialsForm').submit(function(e){
    e.preventDefault();
    
    var email = $('#userEmail').val();
    var message = $('#userMessage').val();
    if(validateEmail(email) && message){
      $('.testimonialsBtn').attr('disabled', true);
      $.post('/send/', $('#testimonialsForm').serialize(), function (data) {
       if(data.result === true){
        $('#testimonialsForm').trigger("reset");
        let userName = $("#userName");
        let userEmail = $("#userEmail");
        let userMessage = $("#userMessage");
        userName.removeClass('correctly');
        userEmail.removeClass('correctly');
        userMessage.removeClass('correctly');
       }else{
        console.log('error');
       }
       $('.testimonialsBtn').attr('disabled', false);
      })
    }
  })

  $('#appStoreOptimizationForm').submit(function(e){
    e.preventDefault();
    var email = $('#userEmail').val();
   
    if(validateEmail(email)){
      $('.btn_form').attr('disabled', true);
      $.post('/send/', $('#appStoreOptimizationForm').serialize(), function (data) {
        if(data.result === true){
          $('#appStoreOptimizationForm').trigger("reset");
          let userName = $("#userName");
          let userEmail = $("#userEmail");
          userName.removeClass('correctly');
          userEmail.removeClass('correctly');
         }else{
          console.log('error');
         }
         $('.btn_form').attr('disabled', false);
      })
    }
  })

  $('#blogForm').submit(function(e){
    e.preventDefault();
    var email = $('#userEmail').val();
   
    if(validateEmail(email)){
      $('.input_submit').attr('disabled', true);
      $.post('/send/', $('#blogForm').serialize(), function (data) {
        console.log(data);
        if(data.result === true){
          $('#blogForm').trigger("reset");
          let userName = $("#userName");
          let userEmail = $("#userEmail");
          let userMessage = $("#userMessage");
          userName.removeClass('correctly');
          userEmail.removeClass('correctly');
          userMessage.removeClass('correctly');
         }else{
          console.log('error');
         }
         $('.input_submit').attr('disabled', false);
      })
    }
  })
  setTimeout(function(){
  window.$zopim || function (e, i) {
      var t = $zopim = function (e) {
          t._.push(e)
      },
      a = t.s = e.createElement("script"),
      n = e.getElementsByTagName("script")[0];
      t.set = function (e) {
        t.set._.push(e)
      }, t._ = [],
      t.set._ = [],
      a.async = !0,
      a.setAttribute("charset", "utf-8"),
      a.src = "//v2.zopim.com/?2oqk0v6JMN9BOV5jxnHK2ZJgIZz51qtV",
      t.t = +new Date,
      a.type = "text/javascript",
      n.parentNode.insertBefore(a, n)
    }
    (document), $zopim(function () {
      $zopim.livechat.setLanguage("en")
    })
  },3000),
  adroll_adv_id = "2XYXIGG7DZCANJJNET2YRA", adroll_pix_id = "CUNCRSRWCVFHDKCNQ6R2FP",
  function (e, i, t, a, n, s, l) {
      e.GoogleAnalyticsObject = "ga", e.ga = e.ga || function () {
      (e.ga.q = e.ga.q || []).push(arguments)
      },
      e.ga.l = 1 * new Date, s = i.createElement(t),
      l = i.getElementsByTagName(t)[0], s.async = 1,
      s.src = "//app-reviews.org/assets/js/analytics.js",
      l.parentNode.insertBefore(s, l)
  };

  (window, document, "script"),
  ga("create", "UA-65130474-1", "auto"),
  ga("require", "displayfeatures"),
  ga("send", "pageview");

if(Boolean(document.getElementsByClassName('lazy').length)){
  $(function() {
    $('.lazy').lazy();
  });
  console.log('hello');
}  

function youTubes_makeDynamic() {

  var $ytIframes = $('iframe[src*="youtube.com"]');

  $ytIframes.each(function (i,e) {

        var $ytFrame = $(e);
        var ytKey;
        var tmp = $ytFrame.attr('src').split(/\//); tmp = tmp[tmp.length - 1]; tmp = tmp.split('?'); ytKey = tmp[0];
        var $ytLoader = $('<div class="ytLoader">');
        $ytLoader.append($('<img class="cover" alt="youtybe" src="https://i.ytimg.com/vi/'+ytKey+'/hqdefault.jpg">'));
        $ytLoader.append($('<div class="playBtn"> </div>'));
        $ytLoader.data('$ytFrame',$ytFrame);
        $ytFrame.replaceWith($ytLoader);

        $ytLoader.click(function () {
            var $ytFrame = $ytLoader.data('$ytFrame');
            $ytFrame.attr('src',$ytFrame.attr('src')+'?autoplay=1');
            $ytLoader.replaceWith($ytFrame);
        });

  });

};

$(document).ready(function () {youTubes_makeDynamic()});
});


