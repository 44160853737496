$(document).ready(function () {
  $('.devices ul li').click(function(){
    var content = $('.content');
    content.removeClass().addClass('content').addClass($(this).data('device'));
  });

  $('.pages').change(function (e) { 
    e.preventDefault();
    var data = $(this).val();

    $('.content iframe').attr('src', '/' + data + '.html');
  });
});